/* eslint-disable prettier/prettier */
import '../../../styles/InfoPanel.scss';
import { Button, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { info, x } from 'react-icons-kit/feather';
import Modal from 'react-modal';
import PartnersDetails from './PartnersDetails';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useState } from 'react';
import classnames from 'classnames';
const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    maxWidth: '1500px',
    transform: 'translate(-50%, -50%)',
  },
};

import checkListPDF from '../../../resourceDocuments/Check_List_Viatura__English.pdf';
import slaPDF from '../../../resourceDocuments/AGE SLA _ Jan 2025.pdf';
import userManualPDF from '../../../resourceDocuments/AGE_International_Jobs_Platform_Manual.pdf';

const InfoPanel: React.FC<InfoPanelProps> = ({ isOpen, toggle }) => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const _toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <Modal isOpen={isOpen} style={modalStyle} ariaHideApp={false} contentLabel="Example Modal">
      <PerfectScrollbar className="info-panel-container">
        <div
          style={{
            display: 'block',
            padding: 30,
          }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '1',
                })}
                onClick={() => {
                  _toggle('1');
                }}>
                Pricing Model
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '2',
                })}
                onClick={() => {
                  _toggle('2');
                }}>
                Member Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '3',
                })}
                onClick={() => {
                  _toggle('3');
                }}>
                Documents
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="pricing-section">
                    <h3>International Jobs Pricing</h3>
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Purchase Price</Label>
                        <Label>Fitting Price Cars</Label>
                        <Label>Fitting Price Vans</Label>
                        <Label>Fitting Price Trucks</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header blue">
                          <Col sm={4}>Windshields/Rear Windows</Col>
                          <Col sm={4}>Side Glass</Col>
                          <Col sm={4}>Rooftops Sunroof</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>x 1.5</Col>
                          <Col sm={4}>x 1.5</Col>
                          <Col sm={4}>x 1.5</Col>
                          <Col sm={4}>92,25 €</Col>
                          <Col sm={4}>57,11 €</Col>
                          <Col sm={4}>241,63 €</Col>
                          <Col sm={4}>103,79 €</Col>
                          <Col sm={4}>57,67 €</Col>
                          <Col sm={4}>241,63 €</Col>
                          <Col sm={4}>172,99 €</Col>
                          <Col sm={4}>92,25 €</Col>
                          <Col sm={4}>241,63 €</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Adhesive</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header blue">
                          <Col sm={4}>Cars/Caravans/Vans</Col>
                          <Col sm={4}>Trucks</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>32,00 €</Col>
                          <Col sm={4}>70,00 €</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Static and Dynamic ADAS Calibration (both)</Label>
                        <Label>Stereo Double Camera and Static ADAS Calibration (both)</Label>
                        <Label>Remote Calibration</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header blue">
                          <Col sm={4}>Cars/Caravans/Vans</Col>
                          <Col sm={4}>Trucks</Col>
                          <Col sm={4}></Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>126,00 €</Col>
                          <Col sm={4}>189,00 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>210,00 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>130,00 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  &nbsp;
                  <div className="pricing-section">
                    <h3>Delivering From Car Dealers</h3>
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Purchase Price</Label>
                        <Label>Fitting Price Cars</Label>
                        <Label>Fitting Price Vans</Label>
                        <Label>Fitting Price Trucks</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header red">
                          <Col sm={4}>Windshields/Rear Windows</Col>
                          <Col sm={4}>Side Glass</Col>
                          <Col sm={4}>Rooftops Sunroof</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>x 1.2</Col>
                          <Col sm={4}>x 1.2</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}>92,25 €</Col>
                          <Col sm={4}>57,67 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}>103,79 €</Col>
                          <Col sm={4}>57,67 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}>172,99 €</Col>
                          <Col sm={4}>92,25 €</Col>
                          <Col sm={4}>n/a</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Adhesive</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header red">
                          <Col sm={4}>Cars/Caravans/Vans</Col>
                          <Col sm={4}>Trucks</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>32,00 €</Col>
                          <Col sm={4}>70,00 €</Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Static and Dynamic ADAS Calibration (both)</Label>
                        <Label>Stereo Double Camera and Static ADAS Calibration (both)</Label>
                        <Label>Remote Calibration</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header red">
                          <Col sm={4}>Cars/Caravans/Vans</Col>
                          <Col sm={4}>Trucks</Col>
                          <Col sm={4}></Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>126,00 €</Col>
                          <Col sm={4}>189,00 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>210,00 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>130,00 €</Col>
                          <Col sm={4}>n/a</Col>
                          <Col sm={4}></Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="pricing-section">
                    <h3>Other Services</h3>
                    <Row className="group example other">
                      <Col sm={4} className="labels">
                        <Label>Windshields Repair Fee</Label>
                        <Label>Mobile Service</Label>
                      </Col>
                      <Col sm={8} className="pricing-table example">
                        <Row className="table-body">
                          <Col sm={4}>63,43 €</Col>
                          <Col sm={4}>(Max 2 repairs)</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>28,83 €</Col>
                          <Col sm={6}>(30km from the nearest branch)</Col>
                          <Col sm={4}>&nbsp;</Col>
                        </Row>
                      </Col>
                    {/* </Row>

                    <Row className="group example other"> */}
                      <Col sm={4} className="labels">
                        <Label>Emergency Glass (Plexiglass)</Label>
                        <Label>After hours</Label>
                      </Col>
                      <Col sm={8} className="pricing-table example">
                        <Row className="table-body">
                          <Col sm={4}>37,00 €</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>93,00 €</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>&nbsp;</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>

                  <div className="pricing-section">
                    <h3>Additional Invoice Rules</h3>
                    <ol>
                      <li>Eurocodes must be included on the invoice.</li>
                      <li>Calibration reports/proof must be uploaded to the platform; otherwise, no payment will be executed for this service.</li>
                      <li>If the glass is purchased by the dealer, the invoice must also be uploaded to the platform (noted in the relevant section when available).</li>
                      <li>The invoice must include the excess information paid by the customer.</li>
                      <li>The invoice is with 0% Taxes always.</li>
                    </ol>
                    <div className="note-box">
                      <strong>Note:</strong> DESA AUTOGLASS is the only member that doesn’t apply this pricing list. So in their case, they apply local prices with attention to AGE colleagues; please do the same.
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <PartnersDetails></PartnersDetails>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <h2>Vehicle Checklist</h2><a
                    href={checkListPDF}
                    download="Check_List_Viatura__English.pdf"
                  >Download</a>

                  <h2>Service Level Agreement (Jan/2025)</h2><a
                    href={slaPDF}
                    download="AGE SLA _ Jan 2025.pdf"
                  >Download</a>

                  <h2>AGE International Jobs Platform Manual</h2><a
                    href={userManualPDF}
                    download="AGE_International_Jobs_Platform_Manual.pdf"
                  >Download</a>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </PerfectScrollbar>
      <Button className="modal-close-btn" onClick={() => toggle()}>
        <Icon icon={x} size={48} />
      </Button>
    </Modal>
  );
};

export default InfoPanel;
